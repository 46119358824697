<div class="center-horizontal">
    <div class="example-form">
        <mat-form-field appearance="fill">
            <mat-label>Full Name</mat-label>
            <input matInput #name>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Gender</mat-label>
            <mat-select [(value)]="selectedGender">
                <mat-option value="male">Male</mat-option>
                <mat-option value="female">Female</mat-option>
                <mat-option value="other">Other</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>DOB</mat-label>
            <input matInput #dob [matDatepicker]="picker">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput #email>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Phone</mat-label>
            <input matInput #phone>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Address</mat-label>
            <input matInput #address>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Pincode</mat-label>
            <input matInput #pincode>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="register()">Register</button>
    </div>
   
</div>