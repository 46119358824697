<div class="center-horizontal">
  <div class="example-form">
    <h1 style="text-align: center;">BLO Login</h1>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Email</mat-label>
      <input type="email" matInput placeholder="Ex. pat@example.com" #email>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Enter your password</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" #password>
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="login()">Log In</button>
  </div>
</div>

<div>
  <h1 mat-dialog-title>Wrong</h1>
  <div mat-dialog-content>
    <p>Wrong Username/Password</p>
  </div>
</div>